import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';


import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import ServiceFour from '../elements/service/ServiceFour';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import AdvancePricingOne from '../elements/advancePricing/AdvancePricingOne';
import BrandTwo from '../elements/brand/BrandTwo';

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderBackOff from "../common/header/Header";
import ContactOne from "../elements/contact/ContactOne";
import ServiceOne from "../elements/service/ServiceOne";


const Home = () => {
    return (
        <>
            <SEO title="Backoff Solutions" />
            <main className="page-wrapper">

                <HeaderTopBar/>
                <HeaderBackOff btnStyle="btn-small" HeaderSTyle="header-not-transparent"/>


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two"
                                        style={{textTransform: 'none'}}>Externalisez votre<br/> {" "}
                                        <Typed
                                            strings={[
                                                "Comptabilité",
                                                "Gestion administrative",
                                                "Télévente",
                                                "Support client",
                                                "Développement logiciel",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">Nous vous aidons à recentrer vos efforts sur ce qui compte vraiment.</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium btn-border round btn-icon" href="#contactUs">Contactez Nous <i className="icon"><FiArrowRight/></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator/>

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Ce que nous pouvons faire pour vous."
                                    title="Nos Services"
                                    description="Des solutions sur mesure pour externaliser vos besoins."
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-start"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator/>

                <div className="container">
                    <div className="row mb--40">
                        <div id="contactUs" className="col-lg-12">
                            <ContactOne/>
                        </div>
                    </div>
                </div>

                <FooterThree/>

            </main>
        </>
)
}

export default Home;
