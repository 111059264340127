import React, { Component } from "react";

class GoogleMapStyle extends Component {
  render() {
    return (
        // Important! Always set the container height explicitly

      <div className="google-map-style-1">
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12770.788072040314!2d10.266154927123237!3d36.8497316897628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd4ab223e4ba5f%3A0x6912fd23b9534c4a!2sLes%20Berges%20Du%20Lac%20II%2C%20Tunis%2C%20Tunisie!5e0!3m2!1sfr!2sfr!4v1726577692371!5m2!1sfr!2sfr"
              width="100%"
              height="100%"
              style={{borderRadius: "10px", position: "relative", border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
